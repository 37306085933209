<template>
  <div class="h-100">
    <div
      v-if="
        isLoadingInstitution || isUpdatingInstitution || isDeletingInstitution
      "
      class="h-100"
    >
      <div class="text-center flex-center h-100">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div style="margin-top: 7rem">
          Chargement des détails de la société...
        </div>
      </div>
    </div>

    <div v-else class="h-100">
      <div class="sidebar-header py-1 px-2">
        <div>
          <h3>{{ institution.name }}</h3>
          <small v-show="institution.code">Code : {{ institution.code }}</small>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          @click.stop="closeSidebar"
          class="close-icon cursor-pointer"
        ></feather-icon>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-action py-05 px-2">
        <div class="d-flex align-items-center">
          <b-button
            v-if="archived"
            variant="warning"
            class="btn-icon"
            @click="restoreInstitutionLocal(institution.id, institution.name)"
          >
            <feather-icon icon="ArrowDownIcon" /> Restaurer
          </b-button>
          <b-button
            v-else
            class="btn-icon"
            variant="primary"
            @click="editInstitution(institution.id)"
            :disabled="archived"
          >
            <feather-icon icon="EditIcon" /> Modifier
          </b-button>
        </div>
        <b-button-group>
          <b-button
            v-if="!archived"
            :disabled="institution.id == institutionSelected.id"
            variant="outline-danger"
            class="btn-icon"
            @click="archiveInstitutionLocal(institution.id, institution.name)"
          >
            <feather-icon icon="ArchiveIcon" />
          </b-button>
        </b-button-group>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-content">
        <div
          v-show="
            institution.email ||
            institution.phoneNumber ||
            institution.secondaryPhoneNumber ||
            institution.webSite
          "
        >
          <h4 class="mb-1 text-primary d-flex justify-content-between">
            <span>
              <feather-icon icon="ChevronRightIcon" /> Moyen de communication
            </span>
          </h4>
          <ul class="listTab">
            <li v-if="institution.email">
              <span>Email</span>
              <span>{{ institution.email }}</span>
            </li>
            <li v-if="institution.phoneNumber">
              <span>Téléphone</span>
              <span>{{ institution.phoneNumber }}</span>
            </li>
            <li v-if="institution.secondaryPhoneNumber">
              <span>Tél secondaire</span>
              <span>{{ institution.secondaryPhoneNumber }}</span>
            </li>
            <li v-if="institution.webSite">
              <span>Site web</span>
              <span>{{ institution.webSite }}</span>
            </li>
          </ul>
          <vs-divider />
        </div>
        <div
          v-show="
            institution.address ||
            institution.zipCode ||
            institution.city ||
            institution.country
          "
        >
          <h4 class="mb-1 text-primary">
            <feather-icon icon="MapPinIcon" /> Adresse
          </h4>
          <p class="paraphbox">
            <span v-show="institution.address"
              >{{ institution.address }}<br
            /></span>
            <span v-show="institution.addressComplement"
              >{{ institution.addressComplement }}<br
            /></span>
            <span v-show="institution.zipCode || institution.city"
              >{{ institution.zipCode }} {{ institution.city }}<br
            /></span>
            <span v-show="institution.country">{{ institution.country }}</span>
          </p>
          <vs-divider />
        </div>
        <div
          v-show="
            institution.rcsCity ||
            institution.registrationNumber ||
            institution.siret ||
            institution.apeCode ||
            institution.capital ||
            institution.tvaNumber
          "
        >
          <h4 class="mb-1 text-primary">
            <feather-icon icon="ClipboardIcon" /> Juridique
          </h4>
          <ul class="listTab">
            <li v-if="institution.registrationNumber">
              <span>N° de RCS</span>
              <span>{{ institution.registrationNumber }}</span>
            </li>
            <li v-if="institution.rcsCity">
              <span>Ville du RCS</span>
              <span>{{ institution.rcsCity }}</span>
            </li>
            <li v-if="institution.siret">
              <span>N° de SIRET</span>
              <span>{{ institution.siret }}</span>
            </li>
            <li v-show="institution.apeCode">
              <span>Code APE</span>
              <span>{{ institution.apeCode }}</span>
            </li>
            <li v-show="institution.capital">
              <span>Capital social</span>
              <span>{{ institution.capital }}</span>
            </li>
            <li v-show="institution.tvaNumber">
              <span>N° de TVA intracommunautaire</span>
              <span>{{ institution.tvaNumber }}</span>
            </li>
          </ul>
          <vs-divider />
        </div>
        <div
          v-show="
            institution.insuranceName ||
            institution.guaranteeType ||
            institution.insuranceCoverage ||
            institution.capital ||
            institution.insuranceAddress ||
            institution.insuranceAddressComplement ||
            institution.insuranceCity ||
            institution.insuranceZipCode ||
            institution.insuranceCountry
          "
        >
          <h4 class="mb-1 text-primary">
            <feather-icon icon="PenToolIcon" /> Garantie et responsabilité
          </h4>
          <ul class="listTab">
            <li v-if="institution.guaranteeType">
              <span>Garantie</span>
              <span>{{
                guaranteeTypeTranslate(institution.guaranteeType)
              }}</span>
            </li>
            <li v-show="institution.insuranceCoverage">
              <span>Zone de couverture</span>
              <span>{{ institution.insuranceCoverage }}</span>
            </li>
            <li v-if="institution.insuranceName">
              <span>Nom de l'assurance</span>
              <span>{{ institution.insuranceName }}</span>
            </li>
          </ul>
          <p
            class="paraphbox"
            v-show="
              institution.insuranceAddress ||
              institution.insuranceAddressComplement ||
              institution.insuranceZipCode ||
              institution.insuranceCity ||
              institution.insuranceCountry
            "
          >
            <span v-show="institution.insuranceAddress"
              >{{ institution.insuranceAddress }}<br
            /></span>
            <span v-show="institution.insuranceAddressComplement"
              >{{ institution.insuranceAddressComplement }}<br
            /></span>
            <span
              v-show="institution.insuranceZipCode || institution.insuranceCity"
              >{{ institution.insuranceZipCode }} {{ institution.insuranceCity
              }}<br
            /></span>
            <span v-show="institution.insuranceCountry">{{
              institution.insuranceCountry
            }}</span>
          </p>
          <vs-divider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import { guaranteeTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      archived: false,
      institution: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingInstitution",
      "isLoadingInstitution",
      "isDeletingInstitution",
      "guaranteeTypeList",
      "institutionSelected",
    ]),
  },
  created() {
    this.getGuaranteeTypeEnum({});
  },
  methods: {
    ...mapActions([
      "getGuaranteeTypeEnum",
      "deleteInstitutions",
      "archiveInstitutions",
      "getInstitution",
      "restoreInstitutions",
    ]),
    guaranteeTypeTranslate,
    getInstitutionDetails(id) {
      this.archived = false;
      if (id != "") {
        this.getInstitution({}).then((res) => {
          this.institution = res;
        });
      } else {
        this.institution = {};
      }
    },
    closeSidebar() {
      this.$emit("closeSidebar");
      this.$emit("refreshGrid");
    },
    async editInstitution(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-institution",
        params: {
          id: this.institution.id,
          title: "Edit : " + this.institution.name,
          tips: "Editer la société : " + this.institution.name,
          routeOrigine: "institutions",
        },
      });
    },
    archiveInstitutionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette société en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver la société "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveInstitutions({
              institutionIds: [id],
            }).then((res) => {
              this.closeSidebar();
            });
          }
        });
    },
    restoreInstitutionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer la société "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreInstitutions({
              institutionIds: [id],
            }).then((res) => {
              this.closeSidebar();
            });
          }
        });
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>
